import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { styles } from '../../styles';

interface Blog {
  _id: string;
  title: string;
  datetime: string; // Use `Date` if you're handling it as a Date object in your app
  id: string;
  image: string;
  category: string;
}

export const HorizontalFeed = ({ blogs }: any) => {
  useEffect(() => {
    document.title = 'Transfers and News';
  }, []);

  return (
    <div id="transfer" className="homeBlock">
      <Row gutter={[16, 16]}>
        {blogs?.map((item: Blog, index: number) => (
          <Col key={index} xs={24} sm={12} md={6} lg={6}>
            <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
              <div style={{ textAlign: 'center'}}>
                <img
                  style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  src={item.image}
                  alt={item?.title}
                />
                <div style={styles.horizontalBlogText}>
                  <p style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'cursive' }}>{item.title}</p>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};
