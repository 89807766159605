import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from '../../../styles';
import { useMediaQuery } from '../../../hooks/MediaQuery.hook';

interface MoreButtonProps {
  blog: any;
}

export const MainBlog: React.FC<MoreButtonProps> = ({ blog }) => {
  const matches = useMediaQuery('(min-width: 768px)');
  return (
    <Link to={`/blogs/${encodeURIComponent(blog?.title)}`}>
        <img style={styles.mainBlogImage} src={blog?.image} alt={blog?.category} />
        <div className='main-blog-desp'>
            <p style={{
                ...styles.slidePostDescription, 
                paddingLeft: matches ? '10px': '0px', 
                margin: '0px',
                textAlign: 'center'
                }}>{blog?.title}</p>
        </div>
    </Link>
  );
};