import React from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import { styles } from '../../../styles';

interface MoreButtonProps {
  url: string;
  buttonStyle: any;
}

export const MoreButton: React.FC<MoreButtonProps> = ({ buttonStyle, url }) => {
  const buttonText = buttonStyle.moreButton ? 'No data': 'More';
  return (
    <Row gutter={24} style={{ ...styles.flexDisplay}}>
      <Link to={url} style={{...styles.moreButton, ...buttonStyle.moreButton}}>{buttonText}</Link>
    </Row>
  );
};