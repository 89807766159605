import { CSSProperties } from 'react';

export const isDefined = (value: any) => value !== undefined && value !== null;
export const isNotDefined = (value: any) => !isDefined(value);

type NamedStyles<T> = { [P in keyof T]: CSSProperties };
export function createStyles<T extends NamedStyles<T> | NamedStyles<any>>(
  styles: T & NamedStyles<any>,
): T {
  return styles;
}

export const getCompetitionTitle = (competitionName: string) => {
  return `${competitionName} News Feed, Fixtures and Updates| Streameastapp.net`;
}

export const extractRouteNameFromPath = (path: string) => path.split('/')?.[1] ?? '';
