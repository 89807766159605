import React from 'react';
import { Col, Row, Typography } from 'antd';
import { styles } from '../../../styles';
import { formatDate, formatTime } from '../../../utils/formatter';
import { useMediaQuery } from '../../../hooks/MediaQuery.hook';
import { HOME_FIXTURE_LENGTH } from '../../../utils/constant';
import { Link } from 'react-router-dom';

interface MoreButtonProps {
  competition: any;
}

export const Fixtures: React.FC<MoreButtonProps> = ({ competition }) => {
  const matches = useMediaQuery('(min-width: 768px)');
  const homeURL = window.location.pathname === '/';
  return (
    <>
      <Typography.Title
        style={{
          ...styles.flexDisplay,
          color: '#fff',
        }}
        level={2}
      >
        FIXTURES
      </Typography.Title>

      {/* Scrollable container for fixtures */}
      <div
        style={styles.fixtureScrollbar}
      >
        {competition?.fixtures?.slice(0, homeURL ? HOME_FIXTURE_LENGTH : competition?.fixtures?.length).map((fixture: any, index: number) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={styles.fixtureContainer}
          >
            <Row
              gutter={[16, 16]} // Adjusted gutter for spacing
              style={{ ...styles.fixtures, color: '#fff', paddingTop: '5px' }}
            >
              <Col span={8} style={{ textAlign: 'start' }}>
                {formatDate(fixture?.fixtureDate + ' ' + fixture?.fixtureTime + ' UTC')}
              </Col>
              <Col span={12} style={{ textAlign: 'start' }}>
                <Link style={styles.colorWhite} to={`/competitions/${competition?.name}`}>{competition?.name}</Link>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ ...styles.fixtures, paddingBottom: '5px' }}>
              <Col
                xs={24}
                sm={8}
                style={{
                  ...styles.fixtureItems,
                  textAlign: matches ? 'left' : 'center',
                  margin: matches ? '10px' : '0px',
                  padding: '0px',
                }}
              >
                {matches && fixture?.teamA?.toUpperCase()}
                <img
                  src={fixture?.teamAImage}
                  alt={fixture?.teamAImage}
                  style={styles.fixtureImage}
                />
                {!matches && fixture?.teamA?.toUpperCase()}
              </Col>
              <Col
                xs={24}
                sm={4}
                style={{
                  ...styles.fixtureItems,
                  textAlign: 'center',
                  margin: matches ? '10px' : '0px',
                }}
              >
                {formatTime(fixture?.fixtureDate + ' ' + fixture?.fixtureTime + ' UTC')}
              </Col>
              <Col
                xs={24}
                sm={8}
                style={{
                  ...styles.fixtureItems,
                  textAlign: matches ? 'left' : 'center',
                  margin: matches ? '10px' : '0px',
                }}
              >
                <img
                  style={styles.fixtureImage}
                  src={fixture?.teamBImage}
                  alt={fixture?.teamBImage}
                />
                {fixture?.teamB?.toUpperCase()}
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    </>
  );
};
